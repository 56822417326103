var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"backdrop",on:{"click":_vm.closeModal}},[_c('FormulateForm',{attrs:{"name":"archive-info-modal"},on:{"submit":_vm.onSave},model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}},[_c('div',{staticClass:"info-modal d-flex align-center justify-end",on:{"click":function($event){$event.stopPropagation();}}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading && _vm.isShowAnimation)?_c('TmpLogoOverlay',{staticClass:"absolute"}):_vm._e()],1),_c('div',{class:[
          'info-modal__content d-flex flex-column',
          {
            'info-modal__content_three-quarter': _vm.cardModeResult === _vm.cardMode.THREE_QUARTHER,
            'info-modal__content_full': _vm.cardModeResult === _vm.cardMode.FULL,
          } ]},[_c('card-modal-toggle-view-size',{attrs:{"section":_vm.section}}),_c('div',{staticClass:"info-modal__close-wrap"},[_c('v-btn',{attrs:{"data-test-id":"closeBtn","icon":""},nativeOn:{"click":function($event){return _vm.closeModal($event)}}},[_c('v-icon',{attrs:{"color":"#21201F"}},[_vm._v("mdi-close")])],1)],1),(_vm.isLoading)?_c('TmpBlock',{staticClass:"info-modal__title ml-6 mr-6 mb-4 mt-12",attrs:{"height":"24"}}):[_c('div',{staticClass:"info-modal__title pl-6 pr-6 pb-4 pt-12",attrs:{"data-test-id":"header"}},[_c('card-modal-toggle-table-items',{on:{"toggle":_vm.changeOpenedId}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.title))])],1)],_c('div',{staticClass:"info-modal__content-wrap"},[_c('v-expansion-panels',{attrs:{"multiple":"","accordion":"","value":_vm.openedPanels},on:{"update:value":function($event){_vm.openedPanels=$event}}},[_c('CardModalChapter',{attrs:{"title":"Общая информация","modalElements":_vm.baseModalElements,"isLoading":_vm.isLoading,"formValues":_vm.formValues,"modalType":_vm.type},scopedSlots:_vm._u([{key:"storageTermValue",fn:function(ref){
        var modalElement = ref.modalElement;
return [_c('v-tooltip',{attrs:{"fixed":"","z-index":300,"top":"","disabled":!_vm.formValues[_vm.fields.STORAGE_TERM_VALUE]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('SelectRolesCard',{attrs:{"isLoading":_vm.isLoading,"title":modalElement.title,"tooltip":modalElement.tooltip,"id":_vm.fields.STORAGE_TERM_VALUE,"items":_vm.storageTermsValues,"is-multiple":false,"is-chips":false,"is-disabled":_vm.type !== _vm.ModalType.EDIT},on:{"change":_vm.changeStorageTerm},model:{value:(_vm.formValues[_vm.fields.STORAGE_TERM_VALUE]),callback:function ($$v) {_vm.$set(_vm.formValues, _vm.fields.STORAGE_TERM_VALUE, $$v)},expression:"formValues[fields.STORAGE_TERM_VALUE]"}})],1)]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.formValues[_vm.fields.STORAGE_TERM_VALUE] ? _vm.formValues[_vm.fields.STORAGE_TERM_VALUE].label : "")+" ")])])]}}])})],1)],1),_c('div',{staticClass:"pa-6 info-modal__footer d-flex"},[(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-space-between archive-info-modal__width"},[_c('TmpBlock',{attrs:{"height":"40","width":"110"}}),_c('div',{staticClass:"d-flex"},[_c('TmpBlock',{staticClass:"mr-2",attrs:{"height":"40","width":"110"}}),_c('TmpBlock',{attrs:{"height":"40","width":"110"}})],1)],1):_vm._e(),[_c('v-btn',{staticClass:"info-modal__btn mr-2",attrs:{"loading":_vm.isSaveLoading,"disabled":_vm.type !== _vm.ModalType.EDIT,"type":"submit","width":"164","height":"40","color":"primary"}},[_c('span',[_vm._v("Сохранить")])]),_c('v-btn',{staticClass:"info-modal__btn",attrs:{"outlined":"","color":"primary","width":"148","height":"40"},on:{"click":_vm.closeModal}},[_c('span',[_vm._v("Отмена")])])]],2)],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }