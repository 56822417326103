
































































































import { defineComponent, ref } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/docTypeView/viewTitle";
import {
  baseModalElements,
  fullModalElements as modalElements,
  techElements,
} from "@monorepo/utils/src/variables/projectsData/docTypeView/modalElements";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { fields, IDocTypeFields } from "@monorepo/utils/src/variables/projectsData/docTypeView/fields";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import DeleteBtn from "@monorepo/uikit/src/components/tableViews/DeleteBtn.vue";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import SelectRolesCard from "@monorepo/uikit/src/components/common/Select/SelectRolesCard.vue";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import { IArchiveCardElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveCardElement";
import { Route } from "vue-router";
import { IDocSectionsMeta, IDocTypeElement, ISubItem } from "@monorepo/catalog/src/views/DocTypeView/types/docTypeElement";
import { mapActions, mapGetters } from "vuex";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { convertSaveCardObject } from "@monorepo/catalog/src/views/DocTypeView/utils/convertSaveCardObject";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import { convertApiItemToUi } from "@monorepo/catalog/src/views/DocTypeView/utils/convertApiItemToUi";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";

export default defineComponent({
  name: "DocTypeInfoModal",
  components: {
    CardModalInfoElement,
    FormInputElement,
    CardModalStatusChip,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    SelectRolesCard,
    ExportBtn,
    DeleteBtn,
    CardModalChapter,
    SelectFilter,
  },
  data(): {
    ModalType: typeof ModalType;
    key: string;
    isSaveLoading: boolean;
    viewUniqKey: string;
    modalElements: IModalElementType[];
    baseModalElements: IModalElementType[];
    techElements: IModalElementType[];
    openedPanels: number[];
    fields: IDocTypeFields;
    isOpenByTemplate: boolean;
    isLoading: boolean;
    cardMode: typeof CardMode;
    section: Sections;
  } {
    return {
      ModalType,
      isSaveLoading: false,
      key: uuid(),
      viewUniqKey,
      isOpenByTemplate: false,
      modalElements,
      baseModalElements,
      techElements,
      cardMode: CardMode,
      section: Sections.ARCHIVE,
      openedPanels: [0, 1],
      fields,
      isLoading: false,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          isOpenByTemplate: boolean;
          changedFormValues: Record<string, unknown>;
          getResultSaveData: (data: Partial<IDocTypeElement>) => Partial<IDocTypeElement>;
          $route: Route;
          refreshData: (archiveId: string) => void;
        },
        value
      ) {
        if (this.isOpenByTemplate) {
          // Открытие по шаблону
          this.isOpenByTemplate = false;
          return;
        }

        if (value) {
          await this.addQueryOpenedId();
          await this.refreshData(value);
        } else {
          this.changedFormValues = this.getResultSaveData({});
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("docTypeView", ["data", "openedId", "fundsLib", "storageTerms", "meta"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    storageTermsValues(): { label: string; id: string }[] {
      return (this.storageTerms as Record<string, string>[]).map((storage) => ({
        id: storage.id,
        label: storage.value || "",
      }));
    },
    storageItems(): { title: string; value: string }[] {
      return convertToSelectItems(this.storageTerms, "value", "id");
    },
    storageItemsIdsObj(): Record<string, Record<string, string>> {
      return this.storageTerms.reduce((result: Record<string, Record<string, string>>, current: Record<string, string>) => {
        result[current.id] = current;
        return result;
      }, {});
    },
    resultStorageTermList(): any[] {
      return (this.formValues.storageTermList || []).map((term: any) => ({
        ...term,
        storageTermValue: term.storageTermValue ? term.storageTermValue.id : null,
      }));
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.ARCHIVE_MODIFY);
    },
    title(): string {
      switch (this.type) {
        case ModalType.EDIT:
        case ModalType.READ:
          return (this.formValues as IArchiveCardElement).name as string;
        case ModalType.NEW:
        default:
          return "Новый вид документа";
      }
    },
    isEditMode(): boolean {
      return this.$route.query.mode === "edit";
    },
    isDraftVersion(): boolean {
      return (this.meta as IDocSectionsMeta)?.version?.state === "DRAFT";
    },
    type(): ModalType {
      return this.isEditMode &&
        this.isDraftVersion &&
        this.user.authorities?.find((element: { authority: string }) => {
          return element.authority === authorities.DOC_KIND_MODIFY;
        })
        ? ModalType.EDIT
        : ModalType.READ;
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("docTypeView", ["changeOpenedId", "addOpenedId", "updateDocument"]),
    ...mapActions("docTypeView", ["getCardElement", "saveCard", "refreshScroll", "deleteElement", "restoreEntity"]),
    refreshData(archiveId: string) {
      this.isLoading = true;
      this.getCardElement(archiveId)
        .then((data: IDocTypeElement) => {
          this.formValues = convertApiItemToUi(data || {});
          this.changedFormValues = this.getResultSaveData(this.formValues);
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeStorageTerm(value: any) {
      if (!value?.id) {
        this.formValues.storageTermValue = null;
      }
    },
    addNewSubDoc() {
      this.formValues = {
        ...this.formValues,
        storageTermList: this.formValues.storageTermList.concat({
          key: `${Math.random()}-key`,
          documentKindId: this.formValues.id,
          storageTermKey: "",
          storageTermValue: "",
          comment: "",
        }),
      };
    },
    deleteSubDoc(subItem: ISubItem) {
      this.formValues.storageTermList = this.formValues.storageTermList.filter((item: ISubItem) =>
        subItem.id ? item.id !== subItem.id : item.key !== subItem.key
      );
    },
    updateSubitem(subItem: ISubItem, fieldKey: string, value: string) {
      this.formValues.storageTermList = this.formValues.storageTermList.map((item: ISubItem) => {
        if (subItem.id ? item.id !== subItem.id : item.key !== subItem.key) {
          return item;
        }
        return {
          ...item,
          [fieldKey]: fieldKey === "storageTermValue" && value ? this.storageItemsIdsObj[value] : value,
        };
      });
    },
    openExport() {
      this.openNewWindow(getFullPath(QUERY_PATH.GET_CATALOG_ARCHIVE_PDF, "/", (this.formValues as IArchiveCardElement).id as string));
    },
    getResultSaveData(data: Partial<IArchiveCardElement>) {
      const resultData: Partial<IArchiveCardElement> = {
        id: data.id || (this.formValues as IArchiveCardElement).id,
        ...data,
      };
      return convertSaveCardObject(resultData);
    },
    async onSave(data: Record<string, string | boolean>) {
      const isNeedSave = (this as unknown as { checkChangesBeforeSave: () => boolean }).checkChangesBeforeSave();
      if (isNeedSave) {
        const resultData = this.getResultSaveData(data);

        this.isSaveLoading = true;
        const isUpdated = await this.updateDocument(resultData).finally(() => {
          this.isSaveLoading = false;
        });

        if (isUpdated) {
          this.changedFormValues = resultData;
          showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
          this.refreshScroll();
          this.refreshData(this.openedId);
        }
      } else {
        return;
      }
    },
    changeContracts(
      this: {
        $formulate: {
          setValues: (key: string, object: Record<string, string>) => void;
        };
      },
      contracts: Record<string, string>[],
      values: Record<string, string>
    ) {
      this.$formulate.setValues("oik-info-modal", Object.assign(values, { contracts }));
    },
    closeModal(): void {
      if (this.type !== ModalType.READ) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
      this.$emit("close");
    },
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref<Record<string, any>>({});
    const changedFormValues = ref({});
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);
    useModalChangeByKeypress(root, "docTypeView", Sections.ARCHIVE, ModalType.READ);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "docTypeView");

    return {
      formValues,
      changedFormValues,
      addQueryOpenedId,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
    };
  },
});
